@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

:root {
  --primary: #12bca2;
  --secondary: #6d15df;
}
h1,h2,h3 {
  margin: 0;
}

body {
  margin: 0;
  color: #333;
  font-family: 'Poppins';
  background: #f2f2f2;
}
nav {
  background: var(--primary);
  padding: 10px 20px;
  text-align: center;
}

nav a {
  color: #f2f2f2;
  margin: 10px;
  display: inline-block;
}
nav h1 {
  color: #fff;
}
.page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

form {
  background: #fff;
  padding: 20px;
  max-width: 480px;
  margin: 0 auto;
  border-radius: 6px;
}
form input, form textarea {
  display: block;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 10px 0 20px 0;
}
form button {
  background: var(--primary);
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px 8px;
  font-family: "Poppins";
  cursor: pointer;
}

.order-by button {
  margin-right: 10px;
  background: var(--primary);
  color: #fff;
  border: 0;
  padding: 4px 8px;
  border-radius: 6px;
  font-family: "Poppins";
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
}

